import { useEffect, useRef, memo, useMemo, useCallback, useState } from "react";
import DataGrid, {
  Column,
  Format,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
  Summary,
  GroupItem,
  Export,
  FilterRow,
  HeaderFilter,
  Pager,
  Selection,
  Item,
  Toolbar,
  StateStoring,
  ColumnChooser,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import SelectBox from "devextreme-react/select-box";

import { useFetch } from "../../../hooks/useFetch";
// import "./run-list.css"
import { gradeMetricForRun } from "src/utils/run-grading";

import kits from "src/pages/report/sequencing-kits.json";

const sequencingKitLookup = (pn) => {
  const obj = kits.find((v) => v.partNumber === pn);
  if (obj) return obj.name;
  return "N/A";
};

const targetedReadsLookup = (pn) => {
  const obj = kits.find((v) => v.partNumber === pn);
  if (obj) return obj.targetedReads;
  return "N/A";
};

const cnstMode = { mode: "single" };
const fields = [
  { name: "Last Week", value: -7 },
  { name: "Last Month", value: 1 },
  { name: "Last 3 Months", value: 3 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last Year", value: 12 },
  { name: "All", value: 0 },
];

const groupCellTemplate = (elem, data) => {
  var formattedValue = `${data.displayValue} (${data.data.aggregates} runs)   ${
    data.groupContinuesMessage ? `  (${data.groupContinuesMessage})` : ""
  }`;
  elem.append(formattedValue);
};

const renderGridCell = (data) => {
  return (
    <div>
      <div
        className="badge"
        onClick={() => alert(data.data.runID)}
        style={{
          display:
            data.data.runID === "6345e6054e6fad0dd71522e1" ? null : "none",
        }}
      >
        10
      </div>
      <div>{data.text}</div>
    </div>
  );
};

const pagesizes = [20, 100, "all"];

const colfixing = { enabled: true };

const customizedDateText = (e) => {
  if (e.value.getFullYear() < 2020) return "";
  return e.valueText;
};

export const getRecipeBundle = (run) => {
  if (run.recipeValues) {
    const recipeValuesSplit = run.recipeValues.split("^^^");
    for (const value of recipeValuesSplit) {
      const splitValue = value.split("###");
      if (splitValue[0] === "RecipesBundleName") return splitValue[1];
    }
  }

  return "";
};

export default memo((props) => {
  const [numMonths, setNumMonths] = useState(-7);
  const {
    data: runs,
    isLoading,
    isFetching,
    refetch,
  } = useFetch("/run?numMonths=" + numMonths);
  const grid = useRef();
  const [refetchRequested, setRefetchRequested] = useState(false);

  useEffect(() => {
    for (let i = 0; i < runs?.length; i++) {
      runs[i].TargetedReads = targetedReadsLookup(runs[i].reagentPartNumber);
      runs[i].SequencingKit = sequencingKitLookup(runs[i].reagentPartNumber);
      runs[i].RecipeBundle = getRecipeBundle(runs[i]);
    }
  }, [runs]);

  useEffect(() => {
    if (!isLoading && runs) {
      const params = new URLSearchParams(
        window.location.hash.replace("#/runs", "")
      );
      const runID = params.get("r");
      if (runID !== "null" && grid && grid.current && grid.current.instance) {
        grid.current.instance.selectRows([runID], false);
        grid.current.instance.navigateToRow(runID);
      }
    }
  }, [isLoading, runs]);

  const onSelectionChanged = useCallback(
    (e) => {
      props.runSelected(e.selectedRowsData[0]);
    },
    [props]
  );

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "Refresh",
      text: "",
      stylingMode: "icon",
      onClick: () => {
        setRefetchRequested(true);
        refetch().then(() => setRefetchRequested(false));
      },
    };
  }, [refetch]);

  const resetOptions = useMemo(() => {
    return {
      hint: "Reset to default table layout",
      text: "Reset",
      stylingMode: "text",
      onClick: () => {
        grid.current.instance.state({});
      },
    };
  }, []);

  const customizeText = useCallback((e) => {
    if (e.value <= 0) return "";
    return e.valueText;
  }, []);

  const cellPrepared = useCallback((e) => {
    if (e.rowType !== "data") return;
    const clr = gradeMetricForRun(
      e.column.name,
      e.value,
      e.data?.reagentPartNumber
    );

    e.cellElement.style.backgroundColor = clr;
    if (!clr) e.cellElement.style.color = "black";
    else e.cellElement.style.color = "white";
  }, []);

  // The latest datagrid implementation with embedded refresh toolbar icon
  return (
    <div id="parentDivList" style={{ margin: 10 }}>
      <SelectBox
        value={numMonths}
        valueExpr="value"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={false}
        dataSource={fields}
        text="Data to query"
        onValueChanged={(v) => {
          setNumMonths(v.value);
          grid.current.instance.getDataSource().reload();
        }}
        style={{ width: "150px" }}
      />

      <DataGrid
        ref={grid}
        className="mygrid"
        dataSource={runs ?? []}
        width="100%"
        height="90vh"
        keyExpr="runID"
        wordWrapEnabled
        showBorders
        showColumnLines
        showRowLines
        selection={cnstMode}
        allowColumnResizing
        allowColumnReordering
        columnResizingMode="widget"
        columnHidingEnabled
        columnAutoWidth
        // columnFixing={colfixing}
        onCellPrepared={cellPrepared}
        onSelectionChanged={onSelectionChanged}
      >
        <Export enabled fileName={"RunsTable"} allowExportSelectedData={true} />
        <GroupPanel visible />
        <SearchPanel visible />
        <Grouping autoExpandAll />
        <FilterRow visible />
        <ColumnChooser visible enabled />
        <HeaderFilter visible />
        <Selection mode={"single"} />
        <StateStoring
          enabled={false}
          type="localStorage"
          storageKey="runsTablev14"
        />

        <Toolbar>
          <Item location="after" widget="dxButton" options={refreshOptions} />
          <Item name="columnChooserButton" />
          <Item name="groupPanel" />
          <Item name="searchPanel" />
          <Item name="exportButton" />
          {/* <Item location="before" widget="dxButton" options={spacerOptions} /> */}
          <Item location="before" widget="dxButton" options={resetOptions} />
        </Toolbar>

        <Column
          //   fixed
          dataField="runName"
          dataType="string"
          cellRender={renderGridCell}
        />
        <Column
          dataField="instrumentName"
          caption="instrument"
          dataType="string"
          groupCellTemplate={groupCellTemplate}
        />
        <Column
          dataField="instrumentSerialNumber"
          caption="Serial Number"
          dataType="string"
          groupCellTemplate={groupCellTemplate}
        />
        <Column
          dataField="instrumentNickname"
          dataType="string"
          groupCellTemplate={groupCellTemplate}
        />
        <Column
          dataField="Date"
          dataType={"date"}
          format={"MMM dd yyyy HH:mm"}
        />

        <Column dataField="side" />
        <Column
          dataField="lastStatusUpdated"
          dataType={"date"}
          format={"MMM dd yyyy HH:mm"}
        />
        <Column dataField="lastStatus" />

        <Column
          dataField="daysSinceLastMaintenanceWash"
          allowEditing={false}
          customizeText={customizeText}
        />

        <Column
          dataField="runOrigin"
          dataType="string"
          groupCellTemplate={groupCellTemplate}
        />

        <Column
          dataField="q30"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField="q40"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField="q50"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="percent" precision={2} />
        </Column>
        <Column
          dataField="avgQ"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="fixedPoint" precision={1} />
        </Column>
        <Column
          dataField="indexAssignmentPercent"
          caption="Index Assignment (%)"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField="error"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="percent" precision={4} />
        </Column>
        <Column
          dataField="poloniesRaw"
          caption="Polonies Raw (M)"
          customizeText={customizeText}
          allowEditing={false}
        >
          <Format type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="poloniesPF"
          caption="Polonies PF (M)"
          customizeText={customizeText}
          allowEditing={false}
        >
          <Format type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="runTime"
          caption="Run Time (Hrs)"
          allowEditing={false}
          customizeText={customizeText}
        >
          <Format type="fixedPoint" precision={2} />
        </Column>

        <Column dataField="RecipeBundle" allowEditing={false} />

        <Column dataField="tenantID" width={150} />
        <Column dataField="version" />
        <Column dataField="runFolderName" />
        <Column dataField="prepType" />
        <Column dataField="chemistryVersion" />
        <Column dataField="kitType" />
        <Column dataField="libraryType" />
        <Column dataField="libraryPools" />
        <Column dataField="SequencingKit" allowEditing={false} />
        <Column dataField="panelID" allowEditing={false} />
        <Column dataField="TargetedReads" allowEditing={false} />
        <Column dataField="readOrder" />
        <Column dataField="highDensity" allowEditing={false} />
        <Column dataField="lowDiversity" allowEditing={false} />
        <Column dataField="shiftPMG" dataType="string" />
        <Column dataField="r1" />
        <Column dataField="r2" />
        <Column dataField="i1" />
        <Column dataField="i2" />
        <Column dataField="numTiles" />
        <Column dataField="tileSelectionModule" />

        <Column dataField="flowcellSerialNumber" />
        <Column dataField="flowcellPartNumber" />
        <Column dataField="flowcellLotNumber" />
        <Column
          customizeText={customizedDateText}
          dataField="flowcellExpiration"
          dataType={"date"}
          format={"MMM dd yyyy"}
        />

        <Column dataField="reagentSerialNumber" />
        <Column dataField="reagentPartNumber" />
        <Column dataField="reagentLotNumber" />
        <Column
          customizeText={customizedDateText}
          dataField="reagentExpiration"
          dataType={"date"}
          format={"MMM dd yyyy"}
        />

        <Column dataField="bufferSerialNumber" />
        <Column dataField="bufferPartNumber" />
        <Column dataField="bufferLotNumber" />
        <Column
          customizeText={customizedDateText}
          dataField="bufferExpiration"
          dataType={"date"}
          format={"MMM dd yyyy"}
        />

        <Summary>
          <GroupItem
            column="instrumentName"
            summaryType="count"
            displayFormat="{0} runs"
          />
        </Summary>

        <Paging defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={pagesizes}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: "#parentDivList" }}
        visible={isFetching || refetchRequested}
        showIndicator={true}
        shading={true}
        // showPane={true}
      />
    </div>
  );
});
