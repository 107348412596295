import { useQuery } from "react-query";
import { useAuth0 as useAuth } from "@auth0/auth0-react";

const REFRESH_INTERVAL_TIMEOUT = 1e3 * 60 * 60;

export const myFetch = async (input, options) =>
  new Promise((resolve, reject) =>
    fetch(input, options)
      .then(async (r) => {
        console.log(r);
        if (r.ok) {
          if (
            [
              r.headers.get("Content-Type"),
              r.headers.get("content-type"),
            ].includes("application/json")
          ) {
            resolve(r.json());
          } else if (
            [
              r.headers.get("Content-Type"),
              r.headers.get("content-type"),
            ].includes("application/octet-stream")
          ) {
            console.log("resolving blob");
            resolve(r.blob());
          } else {
            console.log("resolving text");
            resolve(r.text());
          }
        } else {
          const msg = await r.text();
          reject(new Error(msg));
        }
      })
      .catch((r) => reject(r.target))
  );

export const endpointPath = (urlPath) => {

  var baseurl = process.env.REACT_APP_API_HOST;
  if (!baseurl || baseurl === "") {
    baseurl = "https://telemetry-backend.tools.elembio.io";
  }
  return baseurl + "/api" + urlPath;

}
export const fetchWithToken = async (
  getAccessTokenSilently,
  urlPath,
  options
) => {
  const token = await getAccessTokenSilently();

  const fullurl = endpointPath(urlPath);
 // console.log(fullurl, token);
  return myFetch(fullurl, {
    headers: { Authorization: `Bearer ${token}` },
    ...options,
  });
};

export const useFetch = (
  urlPath,
  enabled = true,
  refetchInterval = REFRESH_INTERVAL_TIMEOUT
) => {
  const { getAccessTokenSilently } = useAuth();
  return useQuery(
    urlPath,
    () =>
      enabled && !!urlPath
        ? fetchWithToken(getAccessTokenSilently, urlPath)
        : () => [],
    {
      refetchInterval,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
};
