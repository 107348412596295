import {
  Chart,
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Label,
  Export,
  Format,
  Grid,
  Tooltip,
  Legend,
  Size,
} from "devextreme-react/chart";
import { useFetch } from "src/hooks/useFetch";
import { Run, SpatialRun } from "../types";

const CytoBarcodeAssigned = ({ run, type }: { run: SpatialRun, type: 'assigned' | 'mismatch' }) => {
  const {
    data: rawData,
    isLoading,
    refetch,
    isError,
    error,
  } = useFetch(`/spatialRun/${run?.runID}/barcodeAssigned?type=${type}`, !!run?.runID);

  const data = (rawData as {}[])?.map((v,i)=>({...v, Well: run.wells[i].WellLocation})) 

  return (
    <>
      <Chart dataSource={data}>
        <Size width="100%" />
        <CommonSeriesSettings argumentField="Well" type="bar" barPadding={0} />
        {run?.batches?.filter((b) => b.PMGMask)
          .map((b) => (
            <Series key={b.Name} valueField={b.Name} name={b.Name} />
          ))}
        <ValueAxis title={`${type === 'assigned' ? "Assigned":"Mismatch"} Rate`}>
          <Grid visible={true} />
        </ValueAxis>
        <ArgumentAxis>
          <Label wordWrap="none" overlappingBehavior="stagger" />
        </ArgumentAxis>
        <Legend
          verticalAlignment="top"
          horizontalAlignment="right"
          // position={"inside"}
        ></Legend>
        <Export enabled />
        <Tooltip enabled>
          <Format type="fixedPoint" precision={1} />
        </Tooltip>
      </Chart>
    </>
  );
};

export default CytoBarcodeAssigned;
