import React, { useState, memo, useEffect, useMemo } from "react";
import RunMetric from "./run-metric";
import RunQuality from "./run-quality";
import RunCyclePlots from "./run-cycleplots";
import RunDetails from "./run-details";
import RunEvents from "./run-events";
import RunTile from "./run-tile";
import PolonyDensity from "./run-tile-polony";
import RunLogs from "./run-logs";
import RunThumbnails from "./run-thumbnails";
import { Run, SpatialRun, TypeRun } from "../types";
import ValuesChart from "./run-valuesCharts";
import RunIndexing from "./run-indexing";
import { SelectBox } from "devextreme-react";
import RunSystemStats from "./run-systemstats";
import RunProcessStats from "./run-processstats";
import RunSSDStats from "./run-ssdstats";
import RunNotifications from "./run-notifications";
import RunBaseComposition from "./run-basecomposition";
import { fetchWithToken, useFetch } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import RunTileCycle from "./run-tile-cycle";
import CycleChart from "./run-cycle-data";
import LegacyPolonyDensity from "./polony-density";
import RunProdstatsIndexing from "./run-prodstats-indexing";
import RunValuesLog from "./run-values-log";
import CytoPolonyCounts from "./run-cyto-polony-counts";
import CytoBarcodeAssigned from "./run-cyto-barcode-assigned copy";

export interface TilesDataType {
  tileLabels: string[];
  rawPolonyCounts: string;
  pfPolonyCounts: string;
  q30ByCycle: string;
  q40ByCycle: string;
  q50ByCycle: string;
  errorByCycle: string;
  intensityByCycle: string;
  backgroundByCycle: string;
  fwhmByCycle: string;
  phasingByCycle: string;
  prephasingByCycle: string;
}

export interface ProdStatsType {
  tiles: TilesDataType;
  polonyCountsInMByLane: number[];
  pfCountsInMByLane: number[];
  avgQByCycle: string;
  avgQByCycleByLane: string;
  indexingRunStats: IndexingStats;
  indexingLaneStats: IndexingStats[];
  alignedR1: number;
  alignedR2: number;
}

export interface IndexingStats {
  Lane: number;
  NumPolonies: number;
  AssignmentRate: number;
  MismatchRate: number;
  SampleIndex: IndexingSampleStats[];
}

export interface IndexingSampleStats {
  SampleName: string;
  SampleNumber: number;
  NumPolonies: number;
  MismatchRate: number;
}

type TelemetryRunContainerProps = {
  selectedRun: Run | SpatialRun;
  typeRun: TypeRun;
  defaultTab: number;
  tabSelected: (tab: string) => void;
};

const spatialTabs = [
  "Details",
  "Events",
  // "Quality & Error",
  // "Base Composition",
  // "Cycle Plots",
  // "Metrics",
  "Polony Counts",
  "Assigned Rate",
  "Mismatch Rate",
  "Sensor Data",
  "System Stats",
  "SSD Stats",
  "Process Stats",
  "Files",
  // "Thumbnails",
  // "Values Log Charts",
  // "Indexing",
  // "Notifications",
];

const getNumDaysSince = (date: Date) => {
  if (date === null) {
    return 90;
  }
  const origDate = new Date(date);
  const currDate = new Date();
  const timeDiff = currDate.getTime() - origDate.getTime();
  return Math.round(timeDiff / (1000 * 3600 * 24));
};

export default memo(
  ({ selectedRun, tabSelected, typeRun }: TelemetryRunContainerProps) => {
    const [prodStatsExists, setProdStatsExists] = useState(false);
    const { getAccessTokenSilently } = useAuth();

    const params = new URLSearchParams(
      window.location.hash.replace("#/runs", "")
    );

    const [selectedTab, setSelectedTab] = useState(
      params.get("t") || "Details"
    );

    useEffect(() => {
      fetchWithToken(
        getAccessTokenSilently,
        "/run/" + selectedRun?.runID + "/prodStatsExists"
      )
        .then((res: boolean) => {
          console.log("exists", res);
          setProdStatsExists(res);
          if (!res) {
            setSelectedTab((prev) => {
              return [
                "Cycle Chart",
                "Polony Density Flow Cell",
                "Cycle Data Flow Cell",
                "Indexing Stats",
                "Values Log",
              ].includes(prev)
                ? ""
                : prev;
            });
          }
        })
        .catch((err) => console.error(err));
    }, [selectedRun?.runID, getAccessTokenSilently]);

    const { data: prodStatsData, isLoading } = useFetch(
      "/run/" + selectedRun?.runID + "/prodStats",
      prodStatsExists
    );

    const tabs = useMemo(() => {
      let msg = "";
      if (!prodStatsExists) msg = " (unavailable)";
      else if (isLoading) msg = " (loading...)";
      const generalTabs = [{ text: "Details" }, { text: "Events" }];

      const legacyTabs = [
        { text: "Quality & Error" },
        { text: "Base Composition" },
      ];

      const prodStatsTabs = [
        {
          text: "Cycle Chart" + msg,
          disabled: !prodStatsData,
        },
        {
          text: "Polony Density Flow Cell" + msg,
          disabled: !prodStatsData,
        },
        {
          text: "Cycle Data Flow Cell" + msg,
          disabled: !prodStatsData,
        },
        {
          text: "Indexing Stats" + msg,
          disabled: !prodStatsData,
        },
      ];

      const valuesLogTabs = [
        {
          text: "Values Log" + (prodStatsExists ? "" : " (unavailable)"),
          disabled: !prodStatsExists,
        },
      ];

      const s3Tabs = [{ text: "Files" }, { text: "Thumbnails" }];

      const snowflakeTabs = [
        { text: "Cycle Plots" },
        { text: "Sensor Data" },
        { text: "System Stats" },
        { text: "SSD Stats" },
        { text: "Process Stats" },
        { text: "Legacy Metrics" },
        { text: "Indexing" },
        { text: "Notifications" },
      ];

      return [
        { key: "General", items: generalTabs },
        { key: "ProdStats Charts", items: prodStatsTabs },
        { key: "Values Log Charts", items: valuesLogTabs },
        { key: "Legacy Charts", items: legacyTabs },
        { key: "S3", items: s3Tabs },
        { key: "Snowflake (slow)", items: snowflakeTabs },
      ];
    }, [prodStatsData, isLoading, prodStatsExists, selectedRun]);

    function getReadsInOrder(
      selectedRun: Run
    ): { read: string; cycles: number }[] {
      const ret = [];
      for (let i = 0; i < selectedRun?.readOrder.length; i += 2) {
        const read = selectedRun?.readOrder.slice(i, i + 2);
        const cycles = selectedRun[read.toLowerCase()];
        ret.push({ read, cycles });
      }
      return ret;
    }

    return (
      <div style={{ flex: "auto", margin: 30 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          Display:&nbsp;&nbsp;&nbsp;
          {typeRun === "Spatial" ? (
            <SelectBox
              width={220}
              items={spatialTabs}
              value={selectedTab}
              onValueChanged={(e) => {
                setSelectedTab(e.value);
                tabSelected(e.value);
              }}
            />
          ) : (
            <SelectBox
              width={250}
              grouped={true}
              valueExpr="text"
              displayExpr="text"
              items={tabs}
              value={selectedTab}
              onValueChanged={(e) => {
                setSelectedTab(e.value);
                tabSelected(e.value);
              }}
            />
          )}
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <hr />
        </div>
        {selectedTab === "Details" && (
          <RunDetails run={selectedRun} typeRun={typeRun} />
        )}
        {selectedTab === "Polony Counts" && <CytoPolonyCounts run={selectedRun as SpatialRun} />}
        {selectedTab === "Assigned Rate" && <CytoBarcodeAssigned run={selectedRun as SpatialRun} type="assigned" />}
        {selectedTab === "Mismatch Rate" && <CytoBarcodeAssigned run={selectedRun as SpatialRun} type="mismatch" />}
        {selectedTab === "Events" && (
          <RunEvents
            runID={selectedRun?.runID}
            typeRun={typeRun}
            days={getNumDaysSince(selectedRun?.Date)}
          />
        )}
        {selectedTab === "Quality & Error" && (
          <RunQuality run={selectedRun as Run} />
        )}
        {selectedTab === "Base Composition" && (
          <RunBaseComposition run={selectedRun as Run} />
        )}
        {selectedTab === "Cycle Plots" && (
          <RunCyclePlots run={selectedRun as Run} />
        )}
        {selectedTab === "Polony Density Flow Cell" && (
          <PolonyDensity data={prodStatsData} isLoading={isLoading} />
        )}
        {selectedTab === "Cycle Chart" && (
          <CycleChart
            data={prodStatsData}
            reads={getReadsInOrder(selectedRun as Run)}
            isLoading={isLoading}
          />
        )}
        {selectedTab === "Cycle Data Flow Cell" && (
          <RunTileCycle
            data={prodStatsData}
            reads={getReadsInOrder(selectedRun as Run)}
            isLoading={isLoading}
          />
        )}
        {selectedTab === "Indexing Stats" && (
          <RunProdstatsIndexing
            data={prodStatsData}
            isLoading={isLoading}
            isDualLibrary={(selectedRun as Run)?.libraryPools === "2"}
          />
        )}
        {selectedTab === "Values Log" && (
          <RunValuesLog runID={selectedRun?.runID} />
        )}
        {selectedTab === "Sensor Data" && (
          <RunMetric
            instrumentID={selectedRun?.instrumentID}
            runID={selectedRun?.runID}
            typeRun={typeRun}
          />
        )}
        {selectedTab === "Files" && selectedRun && (
          <RunLogs
            typeRun={typeRun}
            tenantID={selectedRun.tenantID}
            instrumentID={selectedRun.instrumentID}
            instrumentName={selectedRun.instrumentName}
            instrumentSerialNumber={selectedRun.instrumentSerialNumber}
            runID={selectedRun.runID}
          />
        )}
        {selectedTab === "Thumbnails" && (
          <RunThumbnails run={selectedRun as Run} />
        )}
        {selectedTab === "Values Log Charts" && (
          <ValuesChart run={selectedRun as Run} />
        )}
        {selectedTab === "Indexing" && (
          <RunIndexing runID={selectedRun?.runID} />
        )}
        {selectedTab === "System Stats" && (
          <RunSystemStats
            instrumentID={selectedRun?.instrumentID}
            runID={selectedRun?.runID}
            typeRun={typeRun}
          />
        )}
        {selectedTab === "Process Stats" && (
          <RunProcessStats
            instrumentID={selectedRun?.instrumentID}
            runID={selectedRun?.runID}
            typeRun={typeRun}
          />
        )}
        {selectedTab === "SSD Stats" && (
          <RunSSDStats
            instrumentID={selectedRun?.instrumentID}
            runID={selectedRun?.runID}
            typeRun={typeRun}
          />
        )}
        {selectedTab === "Notifications" && (
          <RunNotifications
            instrumentID={selectedRun?.instrumentID}
            runID={selectedRun?.runID}
          />
        )}
        {selectedTab === "Legacy Metrics" && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            <LegacyPolonyDensity runID={selectedRun?.runID} />
            <RunTile runID={selectedRun?.runID} />
          </div>
        )}
      </div>
    );
  }
);
